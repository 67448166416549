<template>
  <div class="main">
    <div class="box">
      <div class="row">
        <div class="col-60">
          <contact-form></contact-form>
        </div>
        <div class="col-40">
          <div class="contact-info">
              <h3>Safe Orthopaedics SA</h3>
              <p>
                  80 rue Montepy - ZA de Montepy<br />
                  69210 Fleurieux-sur-L’Arbresle
              </p>
              <div class="footer-icon-item">
                  <i class="fa-solid fa-phone footer-icon"></i> 
                  <p>+33 (0) 4 81 09 87 01</p>
              </div>
              <div class="footer-icon-item">
                  <i class="fa-solid fa-print footer-icon"></i> 
                  <p>+33 (0) 1 34 21 12 00</p>
              </div>
          </div>
          <div class="contact-info">
              <h3>Safe Orthopaedics Ltd</h3>
              <p>
                23 Copenhagen Street,<br />
                London N1 0JB<br />
                United Kingdom
              </p>
              <div class="footer-icon-item">
                  <i class="fa-solid fa-phone footer-icon"></i> 
                  <p>+44 203 5144070</p>
              </div>
              <div class="footer-icon-item">
                  <i class="fa-solid fa-print footer-icon"></i> 
                  <p>+44 203 8682 551</p>
              </div>
          </div>
          <div class="contact-info">
              <h3>Safe Orthopaedics GmbH</h3>
              <p>
                Lebacher Strasse 4<br />
                66113 Saarbrücken<br />
                Germany
              </p>
              <div class="footer-icon-item">
                  <i class="fa-solid fa-phone footer-icon"></i> 
                  <p>+49 (0) 6 81 / 99 63 – 275</p>
              </div>
              <div class="footer-icon-item">
                  <i class="fa-solid fa-print footer-icon"></i> 
                  <p>+49 (0) 6 81 / 99 63 – 276</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "./../components/ContactForm.vue";

export default {
  name: "Contact",
  components : { ContactForm }
}
</script>

<style scoped lang="scss">

.main {
  background-image: url("./../assets/contact.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.col-40 {
  padding-left:20px;
  border-left:2px solid $mainColor;
}  

h3 {
  font-size:22px;
  color:$mainColor;
  font-weight: 300;
  margin:0 0 5px 0;
}

.footer-icon-item {
    display: flex;
    align-items: center;
    margin:10px 0;
}

.footer-icon {
    font-size:20px;
    color:$mainColor;
    margin-right: 10px;
    font-weight: 300;
}

.footer-icon-item p, p {
  margin:0;
}

.contact-info {
  margin-bottom:40px;
}

@media screen and (max-width:768px) {
  .col-40 {
    margin-top:80px;
  }
}

</style>